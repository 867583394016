@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

:root { 
    --primary-black: black;
    --primary-copper: rgba(185, 120, 40, 0.85);

    --background: rgb(255, 255, 255);
}

.primary { 
    color: var(--primary);
}

* { 
    margin: 0;
    padding: 0; 
    font-size: 1em; 
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Kanit', sans-serif;
}

a { 
    text-decoration: none;
    color: var(--primary-black);
}

h1 {
    font-size: 1em; 
    color: var(--primary-black);
}
  
ul { 
    list-style-type: none;
}

.image-container {
    width: 100%;
    text-align: center;
}

.oval-container {
    width: 60%; 
    height: 50%;
    margin-top: 50px;
    margin-left: 20%; 
    margin-right: 20%;
    text-align: center;
}

.oval-container img { 
    width: 80%;
    height: 80%;
    margin-left: 10%; 
    margin-right: 10%;
    border-radius: 50%; 
    border-width: thin;
    border-style: solid;
    border-color: var(--primary-black);
    box-shadow: 10px 10px 5px var(--primary-copper)
}

.slide-container {
    width: 60%; 
    height: 50%;
    margin-top: 50px;
    margin-left: 20%; 
    margin-right: 20%;
    text-align: center;
}

.slide-container img { 
    width: 70%;
    height: 70%;
    margin-left: 15%; 
    margin-right: 15%;
    border-width: thin;
    border-style: solid;
    border-color: var(--primary-black);
    box-shadow: 10px 10px 5px var(--primary-copper)
}

@media screen and (max-width: 1200px) { 

    .footer .container { 
        grid-template-columns: repeat(3, 1fr);
    }

    h1 {
        font-size: 1em; 
    }
    
}

